import { gql } from '@apollo/client'

export const GET_COMBINED_THING_DATA = gql`
  query CombinedThingData($thingId: String!) @cached(ttl: 120) {
    # Metadata
    metadata: thing(where: { id: { _eq: $thingId } }) {
      store {
        id
        name
        baseUri
      }
      metadata {
        animation_url
        document
        animation_type
        description
        id
        media
        media_size
        media_type
        title
        type
        youtube_url
        extra
      }
      tokens(limit: 1) {
        minter
      }
    }
    # Lowest price listings / token data
    list(
      where: {
        _and: [
          { thingId: { _eq: $thingId } }
          { autotransfer: { _eq: true } }
          { removedAt: { _is_null: true } }
        ]
      }
      limit: 1
      order_by: { price: asc }
    ) {
      id
      autotransfer
      price
      token {
        id
        minter
        ownerId
        splits {
          id
          account
          txId
        }
        royaltys {
          id
          account
          txId
        }
      }
    }

    tokens_aggregate(
      where: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }

    ownersCount: tokens_aggregate(
      distinct_on: ownerId
      where: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
    ) {
      aggregate {
        count
      }
    }

    simpleSaleCount: lists_aggregate(
      where: {
        removedAt: { _is_null: true }
        token: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
        autotransfer: { _eq: true }
      }
      distinct_on: tokenKey
    ) {
      aggregate {
        count
      }
    }

    rollingAuctionCount: lists_aggregate(
      where: {
        removedAt: { _is_null: true }
        token: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
        autotransfer: { _eq: false }
      }
      distinct_on: tokenKey
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_THING_DATA_SSR = gql`
  query thingDataSSR($id: String!, $includeToken: Boolean!, $tokenId: String) {
    thing(where: { id: { _eq: $id } }) {
      id
      storeId
      metadata {
        title
        description
        media
        extra
        tags
      }
      # used as default for single token things
      tokens(where: { burnedAt: { _is_null: true } }, limit: 1000) {
        id
        minter
        ownerId
      }
      tokenCount: tokens_aggregate(where: { burnedAt: { _is_null: true } }) {
        aggregate {
          count
        }
      }
      token: tokens(where: { id: { _eq: $tokenId } })
        @include(if: $includeToken) {
        minter
        ownerId
      }
    }
  }
`

export const GET_THING_METADATA = gql`
  query GET_THING_METADATA($thingId: String!) {
    thing(where: { id: { _eq: $thingId } }) {
      store {
        id
        name
        baseUri
      }
      metadata {
        animation_url
        document
        animation_type
        description
        id
        media
        media_size
        media_type
        title
        type
        youtube_url
        extra
      }
    }
  }
`

export const GET_TOKEN_THING_MINTER = gql`
  query GET_TOKEN_THING_MINTER($thingId: String!) {
    token(where: { thingId: { _eq: $thingId } }, limit: 1) {
      minter
    }
  }
`

export const GET_LOWEST_THING_PRICE = gql`
  query GET_LOWEST_THING_PRICE($thingId: String!) {
    list(
      where: {
        thingId: { _eq: $thingId }
        autotransfer: { _eq: true }
        removedAt: { _is_null: true }
      }
      limit: 1
      order_by: { price: asc }
    ) {
      id
      autotransfer
      price
      token {
        minter
        ownerId
        splits {
          id
          account
          txId
        }
        royaltys {
          id
          account
          txId
        }
        thing {
          metadata {
            animation_url
            animation_type
            description
            id
            media
            media_size
            media_type
            title
            type
            youtube_url
          }
        }
      }
    }
    tokens_aggregate(
      where: { burnedAt: { _is_null: true }, thingId: { _eq: $thingId } }
    ) {
      aggregate {
        count
      }
    }
    lists_aggregate(
      where: {
        removedAt: { _is_null: true }
        token: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
        autotransfer: { _eq: true }
      }
      distinct_on: tokenKey
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOKEN_BY_THING_ID = gql`
  query GetTokenByThingID($thingId: String!) {
    token(
      limit: 1
      where: {
        thingId: { _eq: $thingId }
        lists: { autotransfer: { _eq: true }, removedAt: { _is_null: true } }
      }
    ) {
      id
      minter
      ownerId
      burnedAt
      splits {
        account
        percent
      }
      royaltys {
        account
        percent
      }
      royaltyPercent
    }
  }
`

export const GET_TOKEN_BY_ID = gql`
  query GetTokenById($tokenId: String!) {
    token(limit: 1, where: { id: { _eq: $tokenId } }) {
      id
      minter
      ownerId
      burnedAt
      splits {
        account
        percent
      }
      royaltys {
        account
        percent
      }
      royaltyPercent
      lists_aggregate(
        where: {
          token: { id: { _eq: $tokenId } }
          autotransfer: { _eq: false }
          removedAt: { _is_null: true }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_LIST_BY_TOKENID = gql`
  query GetListTokenById($tokenId: String!) {
    token(where: { id: { _eq: $tokenId } }) {
      id
      lists(
        where: { removedAt: { _is_null: true }, autotransfer: { _eq: true } }
      ) {
        price
      }
    }
  }
`

export const GET_UNIQUE_OWNERS_BY_THING_ID = gql`
  query GetUniqueOwnersByThingId($thingId: String!) {
    tokens_aggregate(
      distinct_on: ownerId
      where: { thing: { id: { _eq: $thingId } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_OTHER_STORE_THINGS = gql`
  query getOtherStoreItems($id: String!, $limit: Int!) {
    store(where: { id: { _eq: $id } }) {
      name
      owner
      createdAt
      things(
        where: { tokens: { burnedAt: { _is_null: true } } }
        limit: $limit
      ) {
        id
        metadata {
          title
          media
        }
      }
    }
  }
`
export const GET_THING = gql`
  query getToken($id: String!) @cached {
    thing(where: { id: { _eq: $id } }) {
      id
      metaId
      metadata {
        title
        description
        media
        media_hash
        animation_hash
        animation_url
        youtube_url
        document
        document_hash
        extra
        external_url
        category
        type
        visibility
        media_type
        animation_type
        tags
        media_size
        animation_size
      }
      store {
        id
        baseUri
      }
      tokens(
        limit: 5
        where: {
          lists: {
            price: { _is_null: false }
            autotransfer: { _eq: true }
            removedAt: { _is_null: true }
          }
        }
      ) {
        id
        minter
        ownerId
        store {
          baseUri
          name
          id
        }
        royaltys {
          account
          percent
        }
        splits {
          account
          percent
        }
      }
    }
  }
`

export const GET_TOKEN_LIST = gql`
  query MyQuery($ids: [String!]) {
    list(
      where: { token: { id: { _in: $ids } }, removedAt: { _is_null: true } }
    ) {
      price
      token {
        id
      }
    }
  }
`

export const GET_TOKENS_BY_THING_ID = gql`
  query GetTokenByThingID(
    $thingId: String!
    $limit: Int!
    $offset: Int!
    $search_fields: [tokens_bool_exp!]
  ) {
    token(
      where: {
        thingId: { _eq: $thingId }
        burnedAt: { _is_null: true }
        _or: $search_fields
      }
      limit: $limit
      offset: $offset
    ) {
      id
      ownerId
      burnedAt
      lists(where: { removedAt: { _is_null: true } }) {
        autotransfer
      }
    }
    tokens_aggregate(
      where: {
        thing: { id: { _eq: $thingId } }
        burnedAt: { _is_null: true }
        _or: $search_fields
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_TOKENS_SPLITS_BY_THING_ID = gql`
  query GetTokensSplitsByThingID($thingId: String!) {
    token(
      where: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
      limit: 100
    ) {
      id
      ownerId
      splits {
        account
        percent
      }
    }
  }
`

export const GET_TOKENS_ROYS_BY_THING_ID = gql`
  query GetTokensRoysByThingID($thingId: String!) {
    token(
      where: { thingId: { _eq: $thingId }, burnedAt: { _is_null: true } }
      limit: 100
    ) {
      id
      ownerId
      royaltys {
        account
        percent
      }
      royaltyPercent
    }
  }
`

export const GET_THING_MINTERS = gql`
  query GetThingMinters($thingId: String!, $limit: Int!, $offset: Int!) {
    token(
      where: { thingId: { _eq: $thingId } }
      limit: $limit
      offset: $offset
    ) {
      id
      minter
    }
    tokens_aggregate(where: { thingId: { _eq: $thingId } }) {
      aggregate {
        count
      }
    }
  }
`

export const GET_THING_MINTERS_AGG = gql`
  query GetThingMintersAgg($thingId: String!) {
    tokens_aggregate(
      where: { thingId: { _eq: $thingId } }
      distinct_on: minter
    ) {
      aggregate {
        count
      }
    }
  }
`
