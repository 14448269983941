import { EIconName } from 'mintbase-ui'

export const useFileType = (contentType?: string) => {
  if (contentType) {
    if (contentType.includes('audio')) {
      return { label: 'Audio', icon: EIconName.AUDIO }
    } else if (contentType.includes('video')) {
      return { label: 'Video', icon: EIconName.VIDEO }
    } else if (contentType.includes('image')) {
      return { label: 'Image', icon: EIconName.IMAGE }
    } else {
      return { label: '3D Model', icon: EIconName.THREED }
    }
  } else {
    return { label: 'Image', icon: EIconName.IMAGE }
  }
}
