import { EIconName, MbAction, MbIcon, MbModal } from 'mintbase-ui'
import { useState } from 'react'

const ModalSocialShare = ({
  openShareModal,
  setOpenShareModal,
  thingId,
  imageUrl = undefined,
  text = undefined,
  isExternalContract = false,
}) => {
  const [showLinkCopiedText, setShowLinkCopiedText] = useState<boolean>(false)

  // if we dont have a window, return null (SSR)
  if (typeof window === 'undefined') {
    return null
  }

  const thingPermalink = isExternalContract
    ? `https://${window.location}`
    : `https://${window.location.hostname}/thing/${thingId}`
  const urlEscape = (url: string) => url.replace(':', '%3A').replace('/', '%2F')

  const social = {
    twitter: `https://twitter.com/intent/tweet?url=${urlEscape(
      thingPermalink
    )}${text ? `&text=${urlEscape(text)}` : ''}`,

    facebook: `https://www.facebook.com/sharer/sharer.php?u=${urlEscape(
      thingPermalink
    )}`,

    // pinterest: `https://pinterest.com/pin/create/button/?url=${thingPermalink}&media=${thingPermalink}`,

    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${urlEscape(
      thingPermalink
    )}`,
  }

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(thingPermalink)
    setShowLinkCopiedText(true)
    setTimeout(() => setShowLinkCopiedText(false), 3000)
  }

  return (
    <MbModal
      topTitle="Share to"
      open={openShareModal}
      onClose={() => setOpenShareModal(!openShareModal)}
    >
      <div className="flex justify-center items-center h-48">
        <div className="flex gap-12">
          <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            href={social.twitter}
            target={'_blank'}
            rel="noreferrer noopener"
            onClick={() => window.open(social.twitter, '_blank')}
          >
            <MbIcon
              color="white"
              name={EIconName.TWITTER}
              size="20px"
              darkColor="black"
            />
          </a>
          <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            href={social.facebook}
            target={'_blank'}
            rel="noreferrer noopener"
            onClick={() => window.open(social.facebook, '_blank')}
          >
            <MbIcon
              color="white"
              name={EIconName.FACEBOOK}
              size="20px"
              darkColor="black"
            />
          </a>
          {/* <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            href={social.pinterest}
            target={'_blank'}
            rel="noreferrer noopener"
            onClick={() => window.open(social.pinterest, '_blank')}
          >
            <MbIcon
              color="white"
              name={EIconName.PINTEREST}
              size="20px"
              darkColor="black"
            />
          </a> */}
          {/* <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            // href={social.url}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            <MbIcon
              color="white"
              name={EIconName.INSTAGRAM}
              size="20px"
              darkColor="black"
            />
          </a> */}
          {/* <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            // href={social.url}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            <MbIcon
              color="white"
              name={EIconName.TELEGRAM}
              size="20px"
              darkColor="black"
            />
          </a> */}
          <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            href={social.linkedin}
            target={'_blank'}
            rel="noreferrer noopener"
            onClick={() => window.open(social.linkedin, '_blank')}
          >
            <MbIcon
              color="white"
              name={EIconName.LINKEDIN}
              size="20px"
              darkColor="black"
            />
          </a>
          {/* <a
            className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15`}
            // href={social.url}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            <MbIcon
              color="white"
              name={EIconName.DISCORD}
              size="20px"
              darkColor="black"
            />
          </a> */}
        </div>
      </div>
      <div className="text-center">
        <MbAction onClick={handleCopyLink}>
          <div className="flex items-center gap-4">
            <span>Copy link</span>
            <MbIcon
              color={'blue-300'}
              name={EIconName.COPY}
              size="16px"
              darkColor="blue-100"
              className="m-4"
            />
          </div>
        </MbAction>
        {showLinkCopiedText && <p className="p-10">Link copied to clipboard</p>}
      </div>
    </MbModal>
  )
}

export default ModalSocialShare
